<template>
  <div>
    <template>
      <Header />
    </template>
    <router-view />
  </div>
</template>
<script>
import Header from '@/views/partials/Header.vue'
import { mapGetters } from 'vuex'

// import assets styles
require('@/assets/scss/public/bootstrap.scss')
require('@/assets/scss/public/bootstrap-extended.scss')
require('@/assets/scss/public/components.scss')
require('@/assets/scss/public/style.scss')

export default {
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
}
</script>
